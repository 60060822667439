import { Item } from '@/types/supabase.types'
import { round } from './currency'

export const subtotal = (items: Item[]) =>
  round(
    items
      .filter((i) => !i.deleted)
      .map((i) => i.price * i.quantity)
      .reduce((partialSum, a) => partialSum + a, 0)
  )

export const savings = (items: Item[], iva: number): number => round(subtotal(items) - synivaPrice(items, iva))

export const synivaPrice = (items: Item[], iva: number): number => round(subtotal(items) / ((iva / 100) + 1))

export const synivaItemPrice = (item: Item, iva: number): number => round(item.price / ((iva / 100) + 1))

export const shippingInsurance = (items: Item[], iva: number, shippingInsurancePercentage: number | string, shipMode: boolean = false) => {
  let percent: number = 2
  if (!Number.isNaN(Number(shippingInsurancePercentage))) {
    percent = Number(shippingInsurancePercentage)
    const price = (!shipMode) ? synivaPrice(items, iva) : subtotal(items)
    if (!shipMode) {
      return round((price * percent) / 100)
    } else {
      return round((price * percent) / 100)
    }
  } else {
    const { ranges } = JSON.parse(shippingInsurancePercentage as string)

    const price = (!shipMode) ? synivaPrice(items, iva) : subtotal(items)
    if (!shipMode) {
      percent = ranges?.find((range: any) => range.min <= price && range.max > price)?.value
      return round((price * percent) / 100)
    } else {
      percent = ranges?.find((range: any) => range.min <= price && range.max > price)?.value
      return round((price * percent) / 100)
    }
  }
}

export const igic = (items: Item[], iva: number, igic: number, lowValueLimit: number, shipMode: boolean = false) =>{
  if (!shipMode) {
    return round(synivaPrice(items, iva) <= lowValueLimit ? 0 : (synivaPrice(items, iva) * igic) / 100)
  } else {
    return round(subtotal(items) <= lowValueLimit ? 0 : (subtotal(items) * igic) / 100)
  }
}

export const igicForItem = (price: number, quantity: number, iva: number, igic: number, shipMode: boolean = false) => {
  if (shipMode) {
    return round(((price * quantity) * igic) / 100)
  }
  return round((((price / ((iva / 100) + 1)) * quantity) * igic) / 100)
}

export const priceWithoutIva = (quantity: number, iva: number): number => quantity / ((iva / 100) + 1)

export const total = (
  items: Item[],
  iva: number,
  igicP: number,
  lowValueLimit: number,
  shippingInsurancePercentage: any,
  shippingPrice: number,
  shipMode: boolean = false,
  promotionalCode: string | undefined = undefined,
  defaultShippingPrice: number = 0
) => {
  let discount = 0
  let values: string[] = []
  let discountValue = 0

  if (!shipMode) {
    let val = round(
      synivaPrice(items, iva) +
      igic(items, iva, igicP, lowValueLimit) +
      shippingInsurance(items, iva, shippingInsurancePercentage) +
      shippingPrice
    )

    if (promotionalCode) {
      values = promotionalCode.split('|')
      if (values.length === 3) {
        discount = Number(values[2])
        if (values[0] === 'standardShipment' || values[0] === 'onlyStandardShipment' || values[0] === 'onlyEconomyShipment' || values[0] === 'allshipment') {
          discountValue = (values[1] === 'money') ? discount : defaultShippingPrice * (discount / 100)
          val = val - discountValue
        } else if (values[0] === 'price') {
          discountValue = (values[1] === 'money') ? discount : val * (discount / 100)
          val = val - discountValue
        }
      }
    }

    return [val, discountValue]
  } else {
    let val = round(
      subtotal(items) +
      igic(items, iva, igicP, lowValueLimit, shipMode) +
      shippingInsurance(items, iva, shippingInsurancePercentage, shipMode) +
      shippingPrice
    )

    if (promotionalCode) {
      values = promotionalCode.split('|')
      if (values.length === 3) {
        discount = Number(values[2])
        if (values[0] === 'standardShipment' || values[0] === 'onlyStandardShipment' || values[0] === 'onlyEconomyShipment' || values[0] === 'allshipment') {
          discountValue = (values[1] === 'money') ? discount : defaultShippingPrice * (discount / 100)
          val = val - discountValue
        } else if (values[0] === 'price') {
          discountValue = (values[1] === 'money') ? discount : val * (discount / 100)
          val = val - discountValue
        }
      }
    }

    return [val, discountValue]
  }
}
